@import "../../styles/variables.module.scss";

.card {
  flex: 1 auto;
  margin: 0 15px 30px 15px;
  border: none;
  background-color: transparent;
  border-radius: 10px;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  max-width: calc(33.33% - 30px);
  @include large_device {
    max-width: calc(50% - 30px);
  }
  @include mobile_device {
    max-width: 100%;
    margin: 0 0 20px;
  }
  &:hover {
    -webkit-box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.08);
    background-color: $white-color;
  }
  .img {
    position: relative;
    .articleImg {
      position: relative;
      overflow: hidden;
      height: 220px;
      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    span {
      position: absolute;
      background: $white-color;
      font-size: 14px;
      line-height: 23px;
      color: $black-color;
      box-shadow: 0px 6px 20px rgb(0 0 0 / 4%);
      border-radius: 10px 10px 0 0;
      padding: 5px 15px;
      top: 15px;
      left: 15px;
      z-index: 1;
    }
    .cardImg {
      border-radius: 10px 10px 0px 0px;
    }
  }
  .cardBody {
    padding: 10px 20px;
    .articleTitle {
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      margin: 10px 0;
      @include medium_device {
        font-size: 16px;
      }
      a {
        color: $theme-custom-color;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        &:hover {
          color: $theme-color;
        }
      }
    }
    .by,
    li {
      font-size: 12px;
      line-height: 28px;
      color: $theme-custom-color;
    }
    .list {
      li {
        & + li {
          margin-left: 15px;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #878787;
            left: -10px;
            top: 10px;
          }
        }
      }
    }
  }
}
